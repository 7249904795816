var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-production"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('Production')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "primary",
      "title": _vm.$t('RefreshPage')
    },
    on: {
      "click": _vm.refreshPage
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("Printers")) + " "), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchPrinters,
      callback: function callback($$v) {
        _vm.searchPrinters = $$v;
      },
      expression: "searchPrinters"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headersPrinters,
      "items": _vm.printers,
      "custom-filter": _vm.customFilter,
      "search": _vm.searchPrinters,
      "mobile-breakpoint": "0",
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function clickRow($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.printerModel",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PrinterProduction',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                printerBrand: "".concat(item.printerBrand),
                printerModel: "".concat(item.printerModel)
              }
            },
            "title": item.printerModel
          }
        }, [_c('span', [_vm._v(" " + _vm._s(item.printerBrand.replaceAll("_", " ")) + " - " + _vm._s(item.printerModel.replaceAll("_", " ")) + " ")])])];
      }
    }, {
      key: "item.availability",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PrinterProduction',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                printerBrand: "".concat(item.printerBrand),
                printerModel: "".concat(item.printerModel)
              }
            }
          }
        }, [_c('span', {
          class: {
            'red--text': item.availability < 1 / 3,
            'orange--text': item.availability >= 1 / 3 && item.availability < 2 / 3,
            'green--text': item.availability >= 2 / 3
          }
        }, [_vm._v(" " + _vm._s(item.available) + " ")]), _c('span', [_vm._v("/")]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item.quantity))])])];
      }
    }, {
      key: "item.technology",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PrinterProduction',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                printerBrand: "".concat(item.printerBrand),
                printerModel: "".concat(item.printerModel)
              }
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item.technology))])])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "to": {
              name: 'PrinterProduction',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                printerBrand: "".concat(item.printerBrand),
                printerModel: "".concat(item.printerModel)
              }
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }