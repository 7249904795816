<template lang="html" src="./pageProduction.template.vue"></template>

<style lang="scss" src="./pageProduction.scss"></style>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageProduction.i18n');

export default {
  name: 'PageProduction',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headersPrinters: [],
      printers: [],
      searchPrinters: '',
      loading: false,
      modalProduction: false,
      selectedPrinter: null,
      showPrinters: true,
    };
  },
  created() {
    this.getSupplierPrinters();
  },
  mounted() {
    this.headersPrinters = [
      {
        text: this.$t('PrinterModel'),
        value: 'printerModel',
        sortable: false,
      },
      {
        text: this.$t('Availability'),
        value: 'availability',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('NbPartsToProduce'),
        value: 'partsToProduce',
        align: 'center',
      },
      {
        text: this.$t('NbPartsInProduction'),
        value: 'partsInProduction',
        align: 'center',
      },
      { text: this.$t('Technology'), value: 'technology', align: 'center' },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        align: 'center',
      },
    ];
  },
  methods: {
    customFilter(items, search, filter) {
      let formattedItem = null;
      this.printers.forEach(printer => {
        if(items === printer.printerModel) {
          formattedItem = `${printer.printerBrand.replaceAll('_', ' ') } - ${
            printer.printerModel.replaceAll('_', ' ') }`;
        }
      });
      return CustomFilterForSearchDatatable.customFilter(formattedItem ? formattedItem : items, search, this);
    },
    /**
     * GET SUPPLIER PRINTERS
     */
    getSupplierPrinters() {
      this.printers = [];
      this.loading = true;
      this.$apiInstance
        .getSupplierPrintersLinesMetadata(this.$route.params.supplierUUID)
        .then(
          (printersLinesData) => {
            this.loading = false;
            for (const printer of printersLinesData) {
              this.printers.push({
                printerUUID: printer.printerId,
                printerBrand: printer.brand,
                printerModel: printer.model,
                quantity: printer.quantity,
                available: printer.available,
                availability: printer.available / printer.quantity,
                partsToProduce: parseInt(printer.partsToPrint),
                partsInProduction: printer.partsInProduction,
                technology: printer.technology,
              });
            }
          },
          (error) => {
            this.loading = false;
            /**
             * ERROR GET PRINTER
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    refreshPage() {
      this.getSupplierPrinters();
    },
    goToPageItem(item) {
      this.$router.push({name: 'PrinterProduction', params: {supplierUUID: `${this.$route.params.supplierUUID}`, printerBrand: `${item.printerBrand}`, printerModel: `${item.printerModel}`}});
    },
  },
};
</script>
